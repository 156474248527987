@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

*,
html {
  color: white;
  overflow: hidden;
}

.App {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background: #fff;
  /* overflow: hidden; */
  font-family: "Poppins", sans-serif;
}

canvas {
  z-index: 0;
  background-color: black;
}

iframe {
  background-color: black;
}

.header {
  padding-top: 5px;
  margin: 10px;
}

.header .title {
  float: left;
}

.header .subheader {
  float: right;
}

.canvas-container {
  height: 100vh;
  width: 100vw;
}

.main {
  width: 100vw;
  height: 100vh;
  /*background-color: white;*/
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  pointer-events: none;
  color: white;
}

.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.subheader {
  display: flex;
}

.subheader div {
  margin-right: 10px;
}

h1 {
  font-size: 10vw;
}

.content {
}

.content .home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100vw;
}

.content .message {
  min-height: 200px;
  width: 100vw;
  background-color: white;
}

.subheader div {
  font-size: 13px;
  color: white;
  height: 10px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.continue {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
}
