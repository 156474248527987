.greetings {
    position: absolute;
    color: white;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.greetings .sub-menu div{
    margin-top: 10px;
    font-size: 16px;
}